@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.packages-font {
  font-family: "Roboto", sans-serif;
}

.rounded-xl {
  border-radius: 0.7rem;
}

.rounded-xl-r {
  border-radius: 0 0.7rem 0.7rem 0;
}

.text-pre-line {
  white-space: pre-line;
}

.text-black {
  color: #000;
}

.text-violet {
  color: #41245e;
}

.bg-violet {
  background-color: #41245e;
}

.bg-cover {
  background-position: center;
  background-size: cover;
}

.line-height {
  &-1 {
    line-height: 1.3;
  }

  &-2 {
    line-height: 1.5;
  }
}

.btn-pack {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 50px;
  border: unset;
  background: #41245e;
  color: #fff;
  border-radius: 9px;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.btn-pack:hover {
  opacity: 0.9;
}

.btn-pack:active {
  transform: scale(1.03);
}

.not-free {
  opacity: 0.6;
}

.f-icon {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: contain;
}

// ===

.user-image {
  height: 4rem;
  width: 4rem;
}

.b-logo {
  height: 2.5rem;
  width: 2.5rem;
}

.b-logo img {
  object-fit: contain;
}

.upload-image-4 {
  max-width: 19rem;
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  border: 2px dashed #8792a2;
  border-radius: 6px;

  .placeholder {
    height: 4rem;
    width: 4rem;
  }

  .placeholderurl {
    height: 15rem;
    width: 15rem;
  }
}

// group-button-2

.group-button-2 {
  button {
    border: unset;
    padding: 8px 16px;
    background-color: #eceff5;
    border-radius: 8px;

    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #7f8e9d;
  }

  button:hover {
    background-color: #41245e;
    color: #fff;
  }

  button:active {
    transform: scale(1.03);
  }

  button.active {
    background-color: #41245e;
    color: #fff;
  }
}

.step-icon {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    height: 75px;
    width: 75px;
  }
}

.total_container {
  border-top: 1px solid #e5e5e5;
  padding-top: 10px;
}

.steps-bar {
  display: flex;
  justify-content: space-between;
  position: relative;

  .progress-bar-container {
    background: #eff0f7;
    border-radius: 2.5rem;
    height: 6px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .progress-bar {
      height: 6px;
      border-radius: 2.5rem;
      background-color: #41245e;
    }
  }

  .step {
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 10;
    background-color: #eff0f7;
    color: #6f6c90;
    outline: 10px solid #fff;
    font-weight: 400;
    font-size: 1rem;
  }

  .active {
    font-weight: 500;
    background-color: #7840af;
    color: #fff;
  }

  .completed {
    font-weight: 500;
    background-color: #41245e;
    color: #fff;
  }
}

// ====

.rc-slider-handle {
  border: solid 2px #53297c;
}

.rc-slider-handle:hover {
  border-color: #5d2990;
}

.rc-slider-handle:active {
  border-color: #41245e;
  box-shadow: 0 0 5px #41245e;
  cursor: grabbing;
}

.rc-slider-handle:focus {
  border-color: #7a4aaa;
  box-shadow: 0 0 0 5px #6d37a383;
  outline: none;
}

.column-spacebtwn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sb-card {
  box-shadow: 1px 1px 10px -1px #c6a1eb;
  text-align: center;
  border-radius: 5px;
  margin: 30px 0 0;
  padding: 48px 12px;
  cursor: pointer;
  text-align: center;
}

.sb-card:hover .sb-card > button {
  background-color: rgba(102, 51, 153, 1);
  border-color: rgba(102, 51, 153, 1);
  cursor: pointer;
}

.starts-from {
  font-size: 14px;
  font-weight: 400;
  color: #7a4aaa;
}

.card-custom-padd {
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 26px;
  justify-content: center;
}

.plan-price {
  color: white;
  font-size: 28px;
  margin-bottom: 0;
  font-weight: bold;
}

.plan-name {
  margin-bottom: 0;
  color: #7a4aaa;
  font-size: 22px;
  font-weight: 700;
}

.height-76 {
  min-height: 76px;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-top-0 {
  border-top: 0;
}

.subscription-modal-body {
  padding: 0 30px;
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.font-bold {
  font-weight: 700;
}

.starts-from-txt {
  font-size: 12px;
  font-weight: 400;
  color: #b1b1b1;
  margin-top: 16px;
  margin-bottom: 0;
}

.single-plan-container {
  border-radius: 8px;
  max-width: 328px;
}

.subscribe-btn {
  border: 0;
  background-color: rgba(102, 51, 153, 1);
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  height: 42px;
  font-weight: 700;
}

.sub-desc {
  color: #b1b1b1;
  margin-top: 20px;
  height: 41px;
}

.most-popular {
  color: white;
  position: absolute;
  top: -20px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #f44336;
  background: #ed7433;
  right: 0;
  width: fit-content;
  margin: auto;
  padding: 4px 30px;
  font-size: 22px;
  border-radius: 4px;
  font-weight: 700;
}

.black-container {
  background-color: #000;
  padding: 20px 24px;
}

.plan-desc-subtxt {
  color: #b1b1b1;
}

.uc-words {
  text-transform: capitalize;
}

.row-spacebtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.White-txt {
  color: white;
}

.sm-sbtxt {
  color: #727272;
  font-size: 12px;
}

.text-right {
  text-align: right;
}

.box-container {
  max-width: 700px;
  width: 100%;
  padding: 20px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.card-inputs {
  height: 42px;
  border-radius: 6px;
  border: 1px solid #bbbbbb;
  background: #f1f1f1;
  color: #686868;
  padding: 0 16px;
  width: -moz-available;
  width: -webkit-fill-available;
  font-family: "Roboto", sans-serif;
  outline: 0;
}

.mm-yy {
  height: 42px;
  border-radius: 6px;
  border: 1px solid #bbbbbb;
  background: #f1f1f1;
  color: #686868;
  padding: 0 16px;
  width: 42%;
  font-family: "Roboto", sans-serif;
  outline: none;
}

.coupon {
  height: 32px;
  margin-bottom: 8px;
  outline: none;
  width: 200px;
}

.input-label {
  color: #000000;
  text-align: left;
  margin-bottom: 6px;
  margin-top: 25px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.cards-placeholder {
  position: absolute;
  top: 5px;
  right: 0;
  width: 220px;
}

.purple-border-line {
  border-top: 1px solid rgba(102, 51, 153, 0.7);
  border-bottom: 1px solid rgba(102, 51, 153, 0.7);
  padding: 12px 0;
}

.main-title {
  font-weight: 500;
  font-size: 28px;
  margin: 0;
}

.expiry-cvv-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
}

.purchase-decription {
  color: #a1a1a1;
  font-size: 14px;
  text-align: center;
  margin: 30px 0 0;
  padding: 0 20px;
}

.pay-btn {
  background: rgba(237, 116, 51, 1);
  background-color: #663399;
  border: 0;
  height: 42px;
  color: white;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.mt-20 {
  margin-top: 20px;
}

.orange-seperator {
  border-left: 1px solid rgba(255, 193, 7, 0.7);
}

.font-bold {
  font-weight: bold;
}

.mb-10 {
  margin-bottom: 10px;
}

.step3-container {
  background-color: #000;
  padding: 24px;
  margin-right: 0;
  margin-left: 0;
  border-radius: 8px;
}

.purple-border {
  margin-top: 20px;
  border-bottom: 1px solid rgba(102, 51, 153, 0.7);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.cards-container {
  max-height: 145px;
  overflow-y: scroll;
}

.single-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #4f4f4f;
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.master-visa {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #bbbbbb;
  margin-right: 8px;
  cursor: pointer;
}

.master-logo {
  height: 14px;
}

.visa-logo {
  height: 9px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-details p {
  margin-bottom: 0;
  font-size: 10px;
  color: white;
}

.add-new-card {
  background: transparent;
  cursor: pointer;
  border: 0;
  color: rgba(255, 193, 7, 1);
  text-align: left;
  text-decoration: underline;
  margin-top: 8px;
}

.transactions-container {
  max-height: 45px;
  overflow-y: scroll;
}

@media (max-width: 992px) {
  .single-plan-container {
    max-width: initial;
    margin-bottom: 10px;
  }
  .mobile-mb-40 {
    margin-bottom: 40px;
  }
  .mob-text-center {
    text-align: center;
  }
  .border-top-0 button {
    margin: auto;
  }
  .orange-seperator {
    border: 0;
  }
  .black-container {
    padding: 20px 0;
  }
  .box-container {
    padding: 20px 0;
  }
}

@media (max-width: 480px) {
  .step3-container {
    padding: 12px;
  }
  .cards-placeholder {
    display: none;
  }
}

.tarif_card {
  padding: 4px;

  .tarif_card_inner_container {
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }

  .tarif_card_banner {
    position: absolute;
    top: 8px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #41245e;
    font-weight: 600;
    font-size: 10px;
    width: 120px;
    height: 23px;
    background-color: #fff;
    -webkit-transform: rotateZ(35deg) translateX(20%);
    transform: rotateZ(35deg) translateX(20%);
    text-transform: uppercase;
  }

  .tarif_card_list {
    font-size: 12px;
    height: 180px;

    ul {
      padding-left: 16px !important;
    }
  }

  .tarif_card_price_container {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .tarif_card_price {
    font-size: 20px;
    font-weight: 600;
    padding-left: 16px;
    color: #41245e;
  }
  .tarif_span_interval {
    font-size: 14px;
    font-weight: 500;
    color: #41245e;
  }

  .tarif_card_button_selected {
    button {
      width: 100%;
    }
  }

  .tarif_card_button {
    button {
      width: 100%;
      border: rgba(102, 51, 153, 1) 1px solid !important;
      background-color: #fff !important;
      color: #41245e !important;
      font-weight: 600;
    }
  }

  .step-icon {
    height: 60px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
      height: 60px;
      width: 60px;
    }
  }
}

.tarifs_container {
  :first-child {
    margin-right: 10px;
  }

  :last-child {
    margin-left: 10px;
  }
}

.packsSelection_pack {
  border: 1px solid #5f3994;
  border-radius: 12px;
  padding: 18px 15px;
  cursor: pointer;
  color: #5f3994;

  h6 {
    color: #5f3994 !important;
    font-size: 14px !important;
  }
}

.packsSelection_pack_active {
  border: 1px solid #5f3994;
  background-color: #5f3994;
  border-radius: 12px;
  padding: 18px 15px;
  cursor: pointer;
  color: #fff;
  h6 {
    color: #fff !important;
    font-size: 14px !important;
  }
}
.packsSelection_packs {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.warning_component {
  background-color: #feebcb;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;

  h5 {
    font-size: 14px;
    font-weight: 600;
    color: #7b341e;
  }

  p {
    font-size: 14px;
    color: #4f2113;
    margin-bottom: 0;
  }
}
