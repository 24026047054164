body {
  font-family: "Poppins", sans-serif !important;
}

.poppins_font {
  font-family: "Poppins", sans-serif;
}

.tab-content {
  padding: 1rem 0 !important ;
}

.abonnement_tarif_card_list {
  font-size: 12px;
  height: 180px;
  width: 100%;
  max-width: 350px !important;

  ul {
    padding-left: 16px !important;
  }
}
